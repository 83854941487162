:root {
  --font-size1: 3.4rem;
  --font-size2: 2.8rem;
  --font-size3: 2.5rem;
}

a.join-btn {
  margin: 2rem auto;
  color: white;
  display: inline-block;
  font-weight: bold;
  max-width: 200px;
  height: 50px;
  font-size: var(--font-size1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8a00;
  cursor: pointer;
}

.groupby-container {
  padding: 0 3rem;
}

.groupby-container > .content {
  display: block;
}

.product-title-container {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-title {
  font-size: var(--font-size1);
}

.product-title-container > i {
  font-size: var(--font-size1);
  color: #7c00c8;
  margin: 0 1.5rem;
}

.rating-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating-container.left-justified {
  justify-content: flex-start;
}


.star-rating {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.star-rating > li {
  font-size: var(--font-size2);
  color: #7c00c8;
}

.star-rating > li+li {
  margin-left: 0.5rem;
}

.rating {
  display: inline-block;
  padding: 0.2rem 0.8rem;
  margin: 0 1rem;
  font-size: var(--font-size2);
  font-weight: bold;
  color: white;
  border-radius: 0.5rem;
}

.rating.orange {
  background-color: #ff8a00;
}

.rating.purple {
  background-color: #7c00c8;
}

.num-ratings {
  font-size: var(--font-size2);
  font-weight: bold;
}

.views {
  font-size: var(--font-size2);
}

.views > i {
  margin: 0 2rem 0 0;
  color: #ababab;
}

/* .product-image-container {
} */
.product-image-container {
  padding: 2rem;
}
.product-image-container.desktop {
  display: none;
}

.price-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size2);
}

.new-price {
  color: #f01a73;
  font-weight: bold;
  font-size: var(--font-size1);
}

.old-price {
  color: #c5c5c5;
  font-size: var(--font-size2);
  margin: 0 1rem;
  text-decoration: line-through;
}

.countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size2);
}

.timer {
  color: #6c5761;
  font-size: var(--font-size1);
  font-weight: bold;
}

.milestone-container > h2 {
  font-size: var(--font-size2);
  font-weight: bold;
}

.milestone-container > h3 {
  font-size: var(--font-size3);
  color: #ff8a00;
  font-weight: bold;
}

.progress-bar {
  --bar-height: 15px;
  --indicator-width: 25px;
  
  position: relative;
  height: var(--bar-height);
  border: 1px solid silver;
  border-radius: 5px;
  background-color: white;
  overflow: visible;
}

.progress-bar > .bar {
  height: 100%;
  width: 40%;
  background-color: #ff8a00;
}

.progress-bar > .indicator {
  position: absolute;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8a00;
  height: var(--indicator-width);
  width: var(--indicator-width);
  border-radius: var(--indicator-width);
  left: 30%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.progress-label {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  color: gray;
  font-weight: bold;
  font-size: var(--font-size2);
}



.token-reward {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.token-reward > p {
  margin-left: 2rem;
  font-size: var(--font-size3);
}

.bb-coin {
  width: 10rem;
}

.share-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0;
}

.share-container > p {
  font-size: var(--font-size2);
  font-weight: bold;
}

.share-btn {
  border: none;
  background-color: #f01a73;
  color: white;
  border-radius: 1rem;
  padding: 2rem 6rem;
  font-weight: bold;
  margin-right: 2rem;
}

.description-container {
  padding: 2rem 0;
}

.description-container > h2 {
  font-size: var(--font-size2);
  font-weight: bold;
}

.description-container > h1 {
  font-size: var(--font-size1);
  font-weight: bold;
}

.description-container > p {
  font-size: var(--font-size3);
}

.review-container {
  padding: 2rem 0;
}

.review-container > h2 {
  font-size: var(--font-size2);
}

.reviews {
  margin: 5rem 0 0 0;
  padding: 0;
  list-style: none;
}

.review {
  margin-bottom: 5rem;
}
.review > h2 {
  font-size: var(--font-size2);
}

.reviewer-name-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.avatar {
  --width: 8rem;

  background-color: #ababab;
  height: var(--width);
  width: var(--width);
  border-radius: 100%;
  margin-right: 1.5rem;
}

.reviewer-name {
  font-size: var(--font-size2);
  font-weight: bold;
}

.review-text {
  font-size: var(--font-size3);
}

@media (min-width: 800px) {
  :root {
    --font-size1: 2.5rem;
    --font-size2: 1.85rem;
    --font-size3: 1.6rem;
  }
  a.join-btn {
    margin: 2rem 0;
  }
  
  .groupby-container {
    display: flex;
  }
  
  .product-image-container {
    display: none;
  }

  .product-image-container.desktop {
    display: block;
    width: 40%;
  }
  
  .content {
    width: 60%;
  }
  
  .content  hr {
    display: none;
  }
  
  .progress-bar {
    --indicator-width: 30px;
  }
  
  .progress-bar > .indicator {
    font-size: 1.1rem;
  }
  
  .price-container {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
  
  .price {
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .new-price {
    font-size: var(--font-size2);
    margin-left: 1rem;
  }
  .countdown {
    flex-direction: row;
    font-weight: bold;
  }
  .timer {
    font-size: var(--font-size2);
    color: black;
    margin-left: 1rem;
  }
  
  .bb-coin {
    width: 4.5rem;
  }
  .avatar {
    --width: 5rem;
  }

  .share-container {
    display: none;
  }

}

















