#footer {
  /* background-image: url("../../images/Background-footer.png");
  background-size: cover; */
  background-color: #3d3d3d;
  display: flex;
  color: white;
  font-size: 1.6rem;
  font-weight: 500;
  padding:  36px 140px;
  justify-content: space-between;
  bottom: 0;

}

.footer_row_1{
  display: flex;
  width:50%;
}

.footer_row_2{
  display: flex;
  width:50%;
  justify-content: space-around;
}

#footer-contact{
  display:flex;
  flex-direction: column;
}
.contact-d{
  margin-top:1rem !important;
}
.footer-heading {
  font-size: 2rem;
  font-weight: 700;
}

#footer-address,#footer-list,#footer-contact{
  width:22rem;
}
#play-text{
width:40rem;
}
#footer-list {
  display: flex;
  flex-direction: column;
  text-decoration: underline;
}

#footer-list a:hover,#footer-contact a:hover{
  color: white;
}

#play-text {
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}
@media only screen and (max-width: 768px){
  #footer{
    flex-direction: column;
    align-items: center;
    padding:5%;
  }
  .footer_row_1,.footer_row_2{
    width:80%;
    justify-content: space-between;
  }
  #footer-address,#footer-list,#footer-contact,#play-text{
    width: 25rem !important;
    padding: 2%;
    
  }
  #play-text{
    text-align: left;
    align-items: flex-start;
  }
  #footer-contact a{
    margin-top:-0.7rem;
  }
  .contact-d{
    margin-top:0 !important;
  }
}
