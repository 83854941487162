.split {
  display: flex;
  position: relative;
}

.contact-us-outer > h1 {
  margin: 4rem 0;
  text-align: center;
  font-size: 5rem;
  font-weight: 700;
  color: #ff8a00;
}

.contact-us {
  padding: 4rem 2rem;
  margin: 0 auto;
  width: 100%;
}


.contact-us > h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #ff8a00;
}

.contact-us > p {
  font-size: 2.5rem;
}

.emphasis {
  text-decoration: underline;
  color:#ff8a00;
}

.other-queries {
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
}

.other-queries > h3 {
  font-size: 3rem;
  color: #ff8a00;
  margin-bottom: 3rem;
  line-height: 2rem;
}

.other-queries > article:not(:last-child) {
  margin-bottom: 2rem;
}

.other-queries > article > h2 {
  font-size: 2rem;
  font-weight: bold;
}

.other-queries > article > span {
  font-weight: bold;
  color: #757a87;
}

.address-section {
  margin-top: 4rem;
}

.address-section > h2 {
  font-size: 2.5rem;
  color: #ff8a00;
}

.address-section > p {
  margin-bottom: 0.3rem;
  font-size: 2.3rem;
  max-width: 50rem;
}

.contact-us-image {
  width: 40%;
  display: none;
}

.contact-us-image > img {
  width: 100%;
  top: 20rem;
  position: sticky;
}

@media (min-width: 560px){
  
  
  .contact-us > h1 {
    font-size: 3.6rem;
  }
  
  .contact-us > h2 {
    font-size: 2.5rem;
  }

  .contact-us > p {
    font-size: 2rem;
  }

  .other-queries {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .other-queries > h3 {
    font-size: 2.5rem;
  }

  .other-queries > article > span {
    font-size: 1.6rem;
  }
  
  .address-section > h2 {
    font-size: 2.5;
  }
  
  .address-section > p {
    font-size: 1.8rem;
  }
  
}
@media (min-width: 768px) {
  .contact-us {
    width: 60%;
  }
  
  .contact-us-image {
    display: block;
  }
}