.header{
    width:100%;
    z-index: -1;
}


.carousel-img-wrapper{
    background-size:100%;
    background-position:center;
    position: relative;
}
/* .carousel-img{
    
} */

.banner-playbutton-wrapper {
    width: 57%;
    position: absolute;
    top: 70%;
    left: 8%;
}

.banner-playbutton-wrapper .banner-playbutton{
    margin: auto;
    width:30rem !important;
    height: 7rem !important;
    border:0.5rem solid white !important ;
    
} 
.banner-playbutton-wrapper .banner-playbutton #playwhite{
    height:4rem !important;
    width: 4rem !important;
}
.banner-playbutton-wrapper .banner-playbutton #playtext{
    font-size: 2.6rem !important;
    font-weight: 900 !important;
}

@media only screen and (max-width: 768px){
  .banner-playbutton-wrapper .banner-playbutton{
        width:22rem !important;
        height: 5rem !important;
        
    } 
    .banner-playbutton-wrapper .banner-playbutton #playwhite{
        height:3rem !important;
        width: 3rem !important;
    }
    .banner-playbutton-wrapper .banner-playbutton #playtext{
        font-size: 2rem !important;
    }
}
