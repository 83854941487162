.working--image {
  width: 100%;
}
.working--image img{
    width: 100%;
}
.working--content {
  display: flex;
  flex-direction: column;
}
.working--steps {
  margin: 2rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.working--steps--heading {
  font-size: 2.4rem;
  margin: 3rem 0;
  color: #ff8a00;
}

.working--steps--paragraph {
  font-size: 2rem;
}

.working--download--link {
  margin: 8rem auto;
  text-align: center;
}
.working--steps--list{
  font-size: 2rem;
  list-style-type: none;
  margin-left: -4rem;
}

.working--steps--list > li {
  margin-bottom: 2rem;
}

.working--steps--list > li > h2 {
  color: #7c00c8;
  font-size: 2.2rem;
}

.working--steps--list > li > p {
  font-size: 2rem;
}

.working--steps--description{
    width:45%;
}
.working--steps--description--right{
    text-align: right;
}
.working--steps--left{
    flex-direction: row-reverse;
}
.working--steps--image img{
    width: 30rem;
}
.working--play{
  margin: 3rem auto !important;
}

.working--flow--chart {
  width: 65%;
  margin: 4rem auto;
}

.working--flow--chart > img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .working--steps{
      flex-direction: column-reverse;
  }
  .working--steps--description--right{
    text-align: left;
  }

  .working--steps--description{
      margin: 5rem 5%;
      width: 90%;
  }

  .working--steps--heading{
      text-align: center;
  }
  
  .working--flow--chart {
    width: 80%;
    margin: 2rem auto;
  }

}
