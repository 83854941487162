a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
}

.groupbuy-product-card {
  --padding-x: 2rem;
  --font-size1: 3.5rem;
  --font-size2: 2.6rem;
  --footer-height: 10rem;
  --image-height: 150px;

  border: thin solid #7c00c8;
  justify-self: stretch;
  min-width: 0;
}

.groupbuy-product-card > a > h2 {
  padding: var(--padding-x);
  color: #7c00c8;
  font-weight: bold;
  font-size: var(--font-size1);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.groupbuy-product-card > a > img {
  padding: 0 var(--padding-x);
  width: 100%;
  aspect-ratio: 1/1;
  /* height: var(--image-height); */
  object-fit: cover ;
  object-position: center;
}

.groupbuy-product-card-price {
  padding: var(--padding-x);
}

.groupbuy-product-card-price > .new-price {
  color: #f01a73;
  margin-left: 1rem;
  font-size: var(--font-size2);
  font-weight: bold;
}

.groupbuy-product-card-price > .old-price {
  color: #c5c5c5;
  font-size: var(--font-size2);
  text-decoration: line-through;
}

.groupbuy-product-card > a > p {
  padding: 0 var(--padding-x);
}

.past-winners-container {
  padding: var(--padding-x);
  border-top: 1px solid orange;
  border-bottom: 1px solid orange;
  height: 185px;
  overflow-y: scroll;
}

.past-winners-list {
  margin-top: var(--padding-x);
  width: 100%;
}


.past-winners-list td{
  padding: 0.5rem;
  text-align: left;
}

/* .past-winners-item {
  flex-shrink: 0;
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.past-winners-item > b {
  color: green;
  font-size: 15px;
}

.past-winners-item > span {
  font-size: 13px;
}

.past-winners-item:not(:last-child) {
  margin-right: 1.5rem;
} */

.groupbuy-product-card-footer {
  display: flex;
  height: var(--footer-height);
}

.groupbuy-product-card-footer > .countdown-container {
  padding-left: var(--padding-x);
  color: silver;
  font-weight: bold;
  font-size: var(--font-size2);
  width: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.groupbuy-product-card-footer .timer {
  color: black;
  font-size: var(--font-size2);
  margin: 0;
  font-weight: bold;
}


.groupbuy-product-card-footer > a.join-btn-card {
  color: white;
  display: inline-block;
  font-weight: bold;
  width: 50%;
  font-size: var(--font-size1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8a00;
}

.groupbuy-product-card-footer > a:hover {
  background-color: #ffa641;
}

@media (min-width: 800px) {
  .groupbuy-product-card {
    --padding-x: 1rem;
    --font-size1: 2.2rem;
    --font-size2: 1.8rem;
    --footer-height: 6rem;
    --image-height: 250px;
  }

}