
.download-app-container {
  background-image: url('../images/Background-app-install.png');
  background-size: cover;
  height: 736px;
  display: flex;
  padding: 0 140px;
  justify-content: space-between;
  align-items: center;
}

.text-container  {
  width: 50%;
}

.text-container > h1{
  font-size: 64px;
  font-weight: bold;
}

.text-container > h3{
  font-size: 48px;
  font-weight: bold;
}

.text-container > p{
  font-size: 20px;
}

.text-container > button{
  font-size: 24px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 12px 44px;
  border-radius: 8px;
  background: #ff8a00;
  border: none;
}

.what-is-bachatbaazi-contianer {
  padding: 65px 140px;
  background: #f2f2f2;
  height: 460px;
  margin-top: -100px;
}

.what-is-bachatbaazi-contianer > h4 {
  font-size: 24px;
  font-weight: bold;
}

.what-is-bachatbaazi-contianer .answer {
  display: flex;
  margin-top: 30px;
}

.what-is-bachatbaazi-contianer .answer p {
  text-align: justify;
  margin-right: 80px;
  font-size: 20px;
}

.cards-container {
  height: 300px;
  background: #FFFFFF;
  position: relative;
  padding: 0 50px;
}

.cards-floating-container {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: -140px;
}

.cards-floating-container .cards {
  width: 20%;
  height: 400px;
  filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.16 ));
  background: #fff7ec;
  padding: 16px;
  margin-right: 30px;
  border-radius: 20px;
}

.cards-floating-container .cards .heading {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

.cards-floating-container .cards .description {
  color: #000000;
  font-size: 16px;
}


@media only screen and (max-width: 768px) and (min-width: 568px) {

  .download-app-container {
    height: 500px;
    padding: 0 20px;
  }

  .text-container > h1{
    font-size: 40px;
  }
  
  .text-container > h3{
    font-size: 28px;
  }
  
  .text-container > p{
    font-size: 16px;
  }
  
  .text-container > button{
    font-size: 20px;
  }


}

@media only screen and (max-width: 768px) {

  .what-is-bachatbaazi-contianer {
    padding: 20px;
    height: auto;
  }

  .what-is-bachatbaazi-contianer .answer {
    display: block;
  }

  .what-is-bachatbaazi-contianer .answer p {
    margin-right: 20px;
  }

  .cards-container {
    height: auto;
    background: #FFFFFF;
    padding: 0px;
  }

  .cards-floating-container {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    padding: 20px;
    position: unset;
  }
  .cards-floating-container .cards {
    width: auto;
    height: auto;
    margin-right: 0px;
    padding: 16px 16px 36px 16px;
  }
}

@media only screen and (max-width: 568px) {
  .download-app-container {
    height: 360px;
    padding: 0 20px;
  }

  .text-container {
    width: 60%;
    margin-top: -100px;
  }

  .text-container > h1{
    font-size: 26px;
  }
  
  .text-container > h3{
    font-size: 20px;
  }
  
  .text-container > p{
    font-size: 16px;
  }

  .text-container > button {
    font-size: 16px;
    padding: 8px 20px;
  }
}
