.privacy_policy{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    padding:6rem;
}
.privacy_policy_title h1{
    font-size: 3.6rem;
    font-weight: 900;
    color:#FF8A00;
}

.privacy_heading{
    font-size: 2.4rem;
    color:#FF8A00;
    font-weight: 900;
    margin: 2rem 0 !important;
}


.privacy_sections li {
    line-height: normal;
}

 