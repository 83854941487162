.installapp-modal {
  position: relative;
  height: 150px;
}

.installapp-modal > .header {
  font-size: 3rem !important;
}

.installapp-modal > .content p {
  padding: 2rem !important;
  text-align: center;
  font-size: 2.2rem !important;
}

.installapp-modal > .content p div {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
/* .installapp-modal > .content p a {
  color: blue;
  cursor: pointer;
}

.installapp-modal > .content p a:hover {
  text-decoration: underline; */
/* } */


@media (min-width: 450px) {
.installapp-modal {
  height: 200px;
}

.installapp-modal > .header {
  font-size: 2rem !important;
}

.installapp-modal > .content p {
  padding: 1rem !important;
  font-size: 1.5rem !important;
}
}