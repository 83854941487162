.split {
  display: flex;
  position: relative;
}

.terms_and_conditions {
  display: flex;
  flex-direction: column;
  padding: 6rem;
  font-size: 2rem;
  width: 100%
}
.terms_and_conditions_title h1 {
  margin: 4rem 0;
  font-size: 3.6rem;
  font-weight: 900;
  text-align: center;
  color: #ff8a00;
}

.terms_heading, .auction_rules_heading {
  font-size: 2.4rem;
  color: #ff8a00;
  font-weight: 900;
  margin: 2rem 0 !important;
}

.tnc_sections, .auction_rules {
  line-height: normal;
}
.tnc_sections h6 {
  font-size: 2rem;
  font-weight: 700;
}

.terms_and_conditions_image {
  width: 40%;
  display: none;
}

.terms_and_conditions_image > img {
  width: 100%;
  top: 20rem;
  position: sticky;
}

@media (min-width: 768px) {
  .terms_and_conditions {
    width: 60%;
  }
  
  .terms_and_conditions_image {
    display: block;
  }
}