
.UserDetailsFields {
  display: flex;
  flex-direction: column;
  margin:2rem 0;
}
#UserDetailsForm span {
  font-size: 2.4rem;
  color: #ff8a00;
  font-weight: 900;
  text-align: center;
}
#UserDetailsForm label {
  color: #7c00c8;
  font-weight: 700;
  font-size: 1.4rem;
  text-align: left;
}
.UserDetailsFields input {
  border: 1.5px solid #7c00c8;
  padding: 0.5rem;
  font-size:1.4rem;
}
.UserDetailsFields input:focus {
  outline: none !important;
  border: 1.5px solid #7c00c8;
}

#UserDetailsForm p {
  font-size: 1rem;
  color: #484848;
  text-align: center;
}
#UserDetailsForm button {
  padding: 0.8rem;
  background-color: #ff8a00;
  color: white;
  width: 50%;
  margin: 0 25%;
  border: none;
  font-size:1.4rem;
}
#UserDetailsForm .PhoneInputCountry {
  border: 1.5px solid #7c00c8;
  border-right: none;
  margin-right: 0;
  padding: 0 5px;
}
.userDetailsFormOuter {
  display: flex;
  height: 100%;
}
.userDetailsLeft{
  display: flex;
  flex-direction: column;
  width:50%;
  height: 100%;
}
.userdetails-modal .content{
  padding:0 !important;
  height: 100%;
}
.userdetails-modal {
  width: 70rem !important;
  margin: 8rem 50% !important;
  border-radius: 2rem !important;
  height: 50rem;
  transform: translate(-50%);
}
.modals {
  background: transparent !important;
}
.play-store-link , .userDetailsFormRight{
  text-align: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#UserDetailsForm{
  width:80%;
}
.play-store-link p{
  font-size: 2rem;
}
@media only screen and (max-width: 768px){
    .ui .modal .content{
      padding: 0 !important;
  }
  #UserDetailsForm label{
    font-size: 1.6rem;
  }
  #UserDetailsForm input{
    font-size: 1.6rem;
  }
  #UserDetailsForm p{
    font-size: 1.4rem;
  }
  #UserDetailsForm button{
    font-size: 1.6rem;
  }
}