.split {
  display: flex;
  position: relative;
}

.returns-outer > h1 {
  margin: 4rem 0;
  text-align: center;
  font-size: 5rem;
  font-weight: 700;
  color: #ff8a00;
}

.returns {
  padding: 4rem 2rem;
  margin: 0 auto;
  font-size: 2.5rem;
  width: 100%;
}


.returns > h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #ff8a00;
}

.returns td {
  padding-right: 4rem;
}

.returns li {
  margin-top: 2rem;
}

.refunds li {
  margin-top: 2rem;
  display: none;
}

.returns-image {
  width: 40%;
  display: none;
}

.returns-image > img {
  width: 100%;
  top: 20rem;
  position: sticky;
}

@media (min-width: 560px) {
  .returns {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .returns {
    width: 60%;
  }
  
  .returns-image {
    display: block;
  }
}