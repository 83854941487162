#navbar {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 22px 140px;
  align-items: center;
}

.nav-items-contianer {
    display: flex;
}

.nav-items-contianer > a {
  padding: 0 0 0 36px;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.nav-items-contianer a:hover{
  color: #FF8A00;
  text-decoration: none;
  transform: scale(1.1);
}

@media only screen and (max-width: 768px) {
  #navbar {
    padding: 20px;
  }
}