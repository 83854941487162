.split {
  display: flex;
  position: relative;
}

.cancellation-policy-outer > h1 {
  margin: 4rem 0;
  text-align: center;
  font-size: 5rem;
  font-weight: 700;
  color: #ff8a00;
}

.cancellation-policy {
  padding: 4rem 2rem;
  margin: 0 auto;
  width: 100%;
  font-size: 2.5rem;
}

.cancellation-policy > h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #ff8a00;
}

.cancellation-policy td {
  padding-right: 4rem;
}

.cancellation-policy li {
  margin-top: 2rem;
}


.refunds li {
  margin-top: 2rem;
  display: none;
}

.cancellation-policy-image {
  width: 40%;
  display: none;
}

.cancellation-policy-image > img {
  width: 100%;
  top: 20rem;
  position: sticky;
}  

@media (min-width: 560px) {
  .cancellation-policy {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .cancellation-policy {
    width: 60%;
  }
  
  .cancellation-policy-image {
    display: block;
  }
}