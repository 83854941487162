.faq_outer{
    padding:4rem;
    position: relative;
    /* font-family: 'Montserrat', sans-serif !important; */
}

.faq{
    width:40%;
    font-family: unset; 
    padding-left: 5rem;
}

.split {
    display: flex;
    justify-content: space-between;
}

.faq_tabs{
    margin: 1rem 0;
    display: flex;
    justify-content: center;
}

.faq_tabs ul{
    list-style: none;
    background-color: #f4f7f9;
    display: flex;
    padding: 0;
    margin: 0;
    overflow-x: scroll;
}

.faq_tabs > ul > li {
    flex-shrink: 0;
    white-space: nowrap;
    padding: 0 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff8900;
    font-weight: 600;
    height: 10rem;
    width: 15rem;
    transition: all ease-in-out 0.2s;
}

.faq_tabs > ul > li:hover {
    background-color: #ff8900;
    color: #ffffff;
}

.faq_tabs > ul > li.active {
    background-color: #ff8900;
    color: #ffffff;
}


.faq_title{
    margin-bottom: 4rem;;
    font-size: 3.6rem;
    font-weight: 900;
    color:#FF8A00;
    font-family: inherit;
}

.faq_image {
    width:40%;
    height: 40%;
    position: sticky;
    top: 15rem;
}

.faq_question{
    font-size: 2rem !important;
    color:#FF8A00 !important;
    text-indent: -3rem;
    padding-left: 3rem; 
}
.faq_answer{
    font-size: 1.6rem;
}
.faq_image img{
    width:100%;
}

@media only screen and (max-width: 768px){
    .faq_image{
        display: none;
    }
    .faq{
        width:100%;
        
    }

    .faq_tabs > ul > li {
        font-size: 1.8rem;
    }

}
