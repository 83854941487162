.App{
    padding:0;
    line-height: normal;
}

*:not(i){
    font-family: 'Montserrat', sans-serif !important;
}

html {
    font-size: 62.5%;
}

@media only screen and (min-width: 1513px){
    html{
        font-size: 75%;
    }  
}

@media only screen and (max-width: 1512px){
    html{
        font-size: 62.5%;
    }  
}
@media only screen and (max-width: 950px){
    html{
        font-size: 58%;
    }
}
@media only screen and (max-width: 850px){
    html{
        font-size: 53%;
    } 
 } 
 @media only screen and (max-width: 768px){
    html{
        font-size: 48%;
    }
    .App{
    overflow: hidden;
    }
}
@media only screen and (max-width: 480px){
    html{
        font-size: 31.3%;
    }
    .App{
        overflow: hidden;
        }
}
@media only screen and (max-width: 319px){
    html{
        font-size: 31.3%;
    }
    .App{
        overflow: hidden;
        }
} 