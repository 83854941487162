
#google-play{
    background-position: 10% 50%;
    font-weight: 700;
    border:0.3rem solid white;
    border-radius: 5rem;
    height: 5rem;
    width:16rem;
    padding: 0 1%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    color:white;
}
#playtext{
    font-size: 1.6rem !important;
}
#google-play span{
    color:white;
}
#playwhite{
    height:3rem;
    width:3rem;
}


#play-orange {
    background-repeat: no-repeat;
    background-position: 10% 50%;
    font-size: 2rem;
    font-weight: 700;
    border: 0.3rem solid #ff8a00;
    border-radius: 5rem;
    height: 5rem;
    width: 19rem;
    margin-top: -1rem;
    padding: 1%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    font-size:2rem;
  }
  
  #play-orange{
    color: #ff8a00;
    text-align: right;
  }
  #play-orange span{
    color:#ff8a00;
  }
  
  #playorange {
    height: 3rem;
    width: 3rem;
  }

@media only screen and (max-width: 768px) {

#google-play{
    height:4rem;
    width:15rem;
    border:0.3rem solid white;
} 

#playwhite{
    height: 2rem;
    width: 2rem;
}
}