.groupbuy-products-container {
  --font-size1: 3.5rem;

  padding: 0 4rem;
  margin-bottom: 2rem;
}

.groupbuy-products-container > h1 {
  font-size: var(--font-size1);
  font-weight: bold;
  margin: 2rem 0;
  color: #ff8a00;
}

.groupbuy-products-container-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 6rem;
  justify-content: center;
}

.groupbuy-products-container-pagination {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.groupbuy-products-container-pagination .ui.menu {
  font-size: 2.5rem;
}

@media (min-width: 800px) {
  .groupbuy-products-container-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .groupbuy-products-container-pagination .ui.menu {
    font-size: 1.5rem;
  }
}

@media (min-width: 1000px) {
  .groupbuy-products-container-grid {
    grid-template-columns: repeat(3, 1fr);
    gap:3rem;
  }
}

@media (min-width: 1500px) {
  .groupbuy-products-container-grid {
    grid-template-columns: repeat(4, 1fr);
    gap:2rem;
  }
}